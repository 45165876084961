import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {Accordion, AccordionSummary, Button, ButtonGroup, AccordionDetails, Typography, List, ListItem, Checkbox, FormControlLabel, Container, Grid, Box, CircularProgress, Slider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CellarRepository from "../../../data/repository/CellarRepository";
import OriginRepository from "../../../data/repository/OriginRespository";
import DenominationRepository from "../../../data/repository/DenominationRepository";
import BrandRepository from "../../../data/repository/BrandRepository";
import ProductRepository from "../../../data/repository/ProductRepository";
import { useTranslation } from 'react-i18next';
import { Drawer, IconButton } from '@mui/material';
import TokenRepository from "../../../data/repository/TokenRepository";
import MarketplaceCard from './MarketplaceCard';
import { useAuth } from "../../contexts/AuthContext";

const MarketplaceComponent = () => {
    const { t } = useTranslation();

    const { state } = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [products, setProducts] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [cellars, setCellars] = useState([]);
    const [denominations, setDenominations] = useState([]);
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [maxPrice, setMaxPrice] = useState(300);
    const [sliderValue, setSliderValue] = useState({
        minPrice: null,
        maxPrice: null
    });
    
    const cellarRepository = new CellarRepository();
    const originRepository = new OriginRepository();
    const denominationRepository = new DenominationRepository();
    const brandRepository = new BrandRepository();
    const productRepository = new ProductRepository();
    const tokenRepository = new TokenRepository();
    const [filters, setFilters] = useState({
        cellarId: searchParams.get('cellarId') ?? null,
        originId: null,
        denominationId: null,
        brandId: null,
        minPrice: null,
        maxPrice: null,
        benefitId: state?.benefitId ?? null
    });

    const [exchangeMode, setExchangeMode] = useState(0);

    const isMobile = window.innerWidth <= 768;
    const [drawerOpen, setDrawerOpen] = useState(!isMobile);
    const { roles } = useAuth();

    const fetchProducts = async (currentFilters) => {
        try {
            const response = await productRepository.getProductsFiltered(currentFilters);
            setProducts(response.map(product => product));
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false);
        }
    }

    const fetchTokens = async (currentFilters) => {
        try {
            const response = roles.includes("ROLE_CELLAR") ? 
                await tokenRepository.getTokensInBuyback(currentFilters) :  await tokenRepository.getTokensFiltered(currentFilters);

            setProducts(response);
        } catch (error) {
            console.error("Error fetching tokens:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchCellars = async () => {
            try {
                const response = await cellarRepository.getCellarList();
                setCellars(response.map(cellar => cellar));
            } catch (error) {
                console.error("Error fetching cellars:", error);
            }
        }

        const fetchOrigins = async () => {
            try {
                const response = await originRepository.getOriginList();
                setOrigins(response.map(origin => origin));
            } catch (error) {
                console.error("Error fetching origins:", error);
            }
        }

        const fetchDenominations = async () => {
            try {
                const response = await denominationRepository.getDenominationList();
                setDenominations(response.map(denomination => denomination));
            } catch (error) {
                console.error("Error fetching denomination:", error);
            }
        }

        const fetchBrands = async () => {
            try {
                const response = await brandRepository.getBrandList();
                setBrands(response.map(brand => brand));
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        }

        fetchCellars();
        fetchOrigins();
        fetchDenominations();
        fetchBrands();
    }, []);

    useEffect(() => {
        setProducts([]);
        if (exchangeMode) {
            fetchTokens(filters)
        } else {
            fetchProducts(filters);
        }
    }, [filters, exchangeMode])
    
    useEffect(() => {
        
    }, [products])

    const handleFilterChange = (filterType, value) => {
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [filterType]: value ? value : null
            };
            return updatedFilters;
        });
    }

    const handleSliderChange = (filterType, value) => {
        setSliderValue(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [filterType]: value ? value : null
            };
            return updatedFilters;
        });
    }

    const handleDeleteFilters = () => {
        window.history.replaceState({}, document.title)
        setFilters({
            cellarId: searchParams.get('cellarId') ?? null,
            originId: null,
            denominationId: null,
            brandId: null,
            minPrice: null,
            maxPrice: null,
            benefitId: null
        });
    }

    const handleCardClick = (productId) => {
        if (exchangeMode) {
            navigate(`/token/detail/${productId}`);
        } else {
            navigate(`/product/detail/${productId}`);
        }
    };

    /*useEffect(() => {
        setTimeout(() => {
          const param = searchParams.get('cellarId');
          if (param) {
            searchParams.delete('cellarId');
            setSearchParams(searchParams);
          }
        }, 500);
      }, [])*/

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '2rem' }} maxWidth={'xl'}>
            <Typography variant="h3" sx={{marginTop: '2rem', color: exchangeMode ? '#450D5F' : '#F36E1E', fontSize: {xs: '22px', sm: '32px'}, fontWeight: 'bold'}} align="center">
                {t('marketplace.title')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={0.5}></Grid>
                <Grid item xs={12} sm={2.5}>
                    {isMobile && (
                        <>
                            <Button edge="start" style={{ color: exchangeMode ? '#450D5F' : '#F36E1E' }} aria-label="menu" onClick={() => setDrawerOpen(true)}>
                                {t('marketplace.filter')}
                            </Button>
                            <Drawer
                                anchor="left"
                                open={drawerOpen}
                                onClose={() => setDrawerOpen(false)}
                                PaperProps={{
                                    sx: { width: "75%" },
                                  }}
                            >
                                <aside style={{ width: '95%', marginTop: '0.5rem', marginBottom: '2rem' }}>
                                    <Box sx={{margin: '0rem', padding: '0.5rem 1rem', width: 'calc(100% - 2rem)', display: 'flex', flexDirection: 'row', gap: '0rem', justifyContent: 'end', alignItems: 'center'}}>
                                        <svg onClick={ () => setDrawerOpen(false) } style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <path d="M12.5 1L0.999999 12.5" stroke="black"/>
                                            <path d="M1 1L12.5 12.5" stroke="black"/>
                                        </svg>
                                    </Box>
                                    <Typography variant={'h5'} style={{ marginBottom: '1rem', fontSize: '14px', fontWeight: '500' }}>
                                        {t('marketplace.filters')}
                                    </Typography>
                                    {[
                                        { title: t('marketplace.cellars'), items: cellars, filterType: 'cellarId' },
                                        { title: t('marketplace.origin'), items: origins, filterType: 'originId' },
                                        { title: t('marketplace.denomination'), items: denominations, filterType: 'denominationId' },
                                        { title: t('marketplace.brand'), items: brands, filterType: 'brandId' },
                                        { title: t('marketplace.price'),  items: [], filterType: 'price' },
                                    ].map((filterGroup, index) => (
                                        filterGroup.filterType !== 'price' ?
                                        <Accordion key={index} style={{ marginTop: '0px', marginBottom: '0px' }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: exchangeMode ? '#450D5F' : '#F36E1E'}} />} >
                                                <Typography style={{color: exchangeMode ? '#450D5F' : '#F36E1E', fontSize: '14px', fontWeight: '500'}}>{filterGroup.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {filterGroup.filterType !== 'price' ? (
                                                <List>
                                                    {filterGroup.items.map(item => (
                                                        <ListItem key={item.id}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={filters[filterGroup.filterType] === item.id}
                                                                        onChange={(e) => handleFilterChange(filterGroup.filterType, e.target.checked ? item.id : null)}
                                                                    />
                                                                }
                                                                label={<Typography sx={{ fontSize: '14px !important', fontWeight: '500 !important'}}>
                                                                    { item.name }
                                                                </Typography>}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                                ) : filterGroup.filterType === 'price' ? null :
                                                    <Box>
                                                        <Slider
                                                            value={[sliderValue.minPrice || 0, sliderValue.maxPrice || maxPrice]}
                                                            onChange={(event, newValue) => {
                                                                setSliderValue(prevFilters => ({
                                                                    ...prevFilters,
                                                                    minPrice: newValue[0],
                                                                    maxPrice: newValue[1]
                                                                }));
                                                            }}
                                                            onChangeCommitted={(event, newValue) => {
                                                                handleFilterChange('minPrice', newValue[0]);
                                                                handleFilterChange('maxPrice', newValue[1]);
                                                            }}
                                                            step={20}
                                                            valueLabelDisplay="auto"
                                                            aria-labelledby="range-slider"
                                                            max={maxPrice}
                                                            sx={{
                                                                color: exchangeMode ? '#450D5F' : '#F36E1E',
                                                                "& .MuiSlider-thumb": {
                                                                    color: exchangeMode ? '#450D5F' : '#F36E1E'
                                                                },
                                                                "& .MuiSlider-track": {
                                                                    color: exchangeMode ? '#450D5F' : '#F36E1E'
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                }
                                            </AccordionDetails>
                                        </Accordion> : null
                                    ))}
                                    {   Object.values(filters).every(item => item === null) ?
                                        null :
                                        <Typography variant={'h6'} 
                                            style={{ marginTop: '0.5rem', marginBottom: '1rem', fontSize: '12px', fontWeight: '500', color: exchangeMode ? '#450D5F' : '#F36E1E', textAlign: 'right', cursor: 'pointer' }}
                                            onClick={ handleDeleteFilters }
                                        >
                                            {t('marketplace.deleteFilters')}
                                        </Typography>
                                    }
                                </aside>
                            </Drawer>
                        </>
                    )}

                    {!isMobile && (
                        <aside style={{ width: '100%', marginRight: '1rem', marginTop: '5rem', marginBottom: '2rem' }}>
                            <Typography variant={'h5'} style={{ marginBottom: '1rem', fontSize: '14px', fontWeight: '500' }}>
                                {t('marketplace.filters')}
                            </Typography>
                            {[
                                { title: t('marketplace.cellars'), items: cellars, filterType: 'cellarId' },
                                { title: t('marketplace.origin'), items: origins, filterType: 'originId' },
                                { title: t('marketplace.denomination'), items: denominations, filterType: 'denominationId' },
                                { title: t('marketplace.brand'), items: brands, filterType: 'brandId' },
                                { title: t('marketplace.price'),  items: [], filterType: 'price' },
                            ].map((filterGroup, index) => (
                                filterGroup.filterType !== 'price' ?
                                <Accordion key={index} style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{color: exchangeMode ? '#450D5F' : '#F36E1E'}} />} >
                                        <Typography sx={{color: exchangeMode ? '#450D5F' : '#F36E1E', fontSize: '14px', fontWeight: '500'}}>{filterGroup.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {filterGroup.filterType !== 'price' ? (
                                            <List>
                                                {filterGroup.items.map(item => (
                                                    <ListItem key={item.id}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={filters[filterGroup.filterType] === item.id}
                                                                    onChange={(e) => handleFilterChange(filterGroup.filterType, e.target.checked ? item.id : null)}
                                                                />
                                                            }
                                                            label={<Typography sx={{ fontSize: '14px !important', fontWeight: '500 !important'}}>
                                                                { item.name }
                                                            </Typography>}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : filterGroup.filterType === 'price' ? null :
                                            <Box>
                                                <Slider
                                                    value={[sliderValue.minPrice || 0, sliderValue.maxPrice || maxPrice]}
                                                    onChange={(event, newValue) => {
                                                        setSliderValue(prevFilters => ({
                                                            ...prevFilters,
                                                            minPrice: newValue[0],
                                                            maxPrice: newValue[1]
                                                        }));
                                                    }}
                                                    onChangeCommitted={(event, newValue) => {
                                                        handleFilterChange('minPrice', newValue[0]);
                                                        handleFilterChange('maxPrice', newValue[1]);
                                                    }}
                                                    step={20}
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="range-slider"
                                                    max={maxPrice}
                                                    sx={{
                                                        color: exchangeMode ? '#450D5F' : '#F36E1E',
                                                        "& .MuiSlider-thumb": {
                                                            color: exchangeMode ? '#450D5F' : '#F36E1E'
                                                        },
                                                        "& .MuiSlider-track": {
                                                            color: exchangeMode ? '#450D5F' : '#F36E1E'
                                                        }
                                                    }}
                                                />
                                                </Box>
                                        }
                                    </AccordionDetails>
                                </Accordion> : null
                            ))}
                            {   Object.values(filters).every(item => item === null) ?
                                null :
                                <Typography variant={'h6'} 
                                    style={{ marginTop: '0.5rem', marginBottom: '1rem', fontSize: '14px', fontWeight: '500', color: exchangeMode ? '#450D5F' : '#F36E1E', textAlign: 'right', cursor: 'pointer' }}
                                    onClick={ handleDeleteFilters }
                                >
                                    {t('marketplace.deleteFilters')}
                                </Typography>
                            }
                        </aside>
                    )}
                </Grid>

            {loading ? (
                <Grid item xs={12} sm={9}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                        <CircularProgress size={80} style={{ color: exchangeMode ? '#450D5F' : '#F36E1E' }} />
                    </div>
                </Grid>
            ) : (
                <Grid item xs={12} sm={9}>
                    <Grid item xs={12} sx={{ marginTop: { xs: '0', sm: '4rem' } }}>
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                            <Button
                                onClick={() => { setLoading(true); setExchangeMode(0); }}
                                style={{backgroundColor: exchangeMode ? '#878787' : '#F36E1E'}}
                            >{t('marketplace.box1')}</Button>
                            <Button
                                onClick={() => { setLoading(true); setExchangeMode(1); }}
                                style={{backgroundColor: exchangeMode ? '#450D5F' : '#878787'}}
                            >{t('marketplace.box2')}</Button>
                        </ButtonGroup>
                    </Grid>
                    <Box mt={2} sx={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', alignItems: 'center' }}>
                    {   !products.length ?
                        <Typography variant="p" sx={{width: '100%', textAlign: 'center', color: 'red', fontSize: {xs: '12px', sm: '16px'}, lineHeight: '3rem'}}>
                            {t('marketplace.emptyProducts')}
                        </Typography>
                        :
                        products.map((product, index) => (
                            <MarketplaceCard
                                key = { index }
                                product = { product }
                                exchangeMode = { exchangeMode }
                                handleCardClick = { handleCardClick }
                                dark = { false }
                            />
                    ))}
                    </Box>
            </Grid>
            )}
            </Grid>
        </Container>
    );
}

export default MarketplaceComponent;
