import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Container, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import NewsRepository from "../../data/repository/NewsRepository";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const HomeNews = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const newsRepository = new NewsRepository();

  const fetchNews = async () => {
    try {
      const response = await newsRepository.getNewsList();
      setNews(response.map((benefits) => benefits));
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <Container
      id="novedades"
      style={{ scrollMarginTop: "6rem", overflow: "hidden" }}
    >
      {news?.length > 0 ? (
        <Container
          id="marketplace-news"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: "6rem",
          }}
          maxWidth={"xl"}
        >
          <Grid
            container
            mb={10}
            spacing={5}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{
                  color: "white",
                  fontSize: { xs: "20px", sm: "40px" },
                  fontWeight: "bold",
                }}
                align="center"
              >
                {t("home.news.news")}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: { xs: "1.5rem", sm: "3rem" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                width: "100%",
                gap: { xs: "0.5rem", sm: "2rem" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Swiper
                spaceBetween={16}
                //slidesPerView={3}
                breakpoints={{
                  998: { slidesPerView: 3 },
                  768: { slidesPerView: 2 },
                  578: { slidesPerView: 1 },
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                //onSlideChange={() => console.log('slide change')}
                //onSwiper={(swiper) => console.log(swiper)}
                modules={[Navigation]}
              >
                {news.length > 0 &&
                  news?.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      elevation={0}
                      style={{
                        width: "268px",
                        backgroundColor: "transparent",
                        padding: "2px",
                        boxSizing: "border-box",
                        borderRadius: "10px",
                        cursor: "pointer",
                        alignSelf: "stretch",
                      }}
                    >
                      <Box
                        key={item.id}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <img
                          src={item.image}
                          alt={`Icon ${index}`}
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                            width: "100%",
                            height: "280px",
                            border: "1px solid transparent",
                            borderRadius: "10px",
                            background:
                              "linear-gradient(270.57deg, #FC6964 0.49%, #F36E1E 49.34%, #FCC442 96.2%) border-box",
                          }}
                          onClick={() => window.open(item.link, "_blank")}
                        />
                        <Typography
                          variant="h5"
                          gutterBottom
                          align="start"
                          mt={3}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            fontSize: "20px",
                            textTransform: "uppercase",
                            backgroundImage:
                              "linear-gradient(270.57deg, #FC6964 0.49%, #F36E1E 49.34%, #FCC442 96.2%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            fontWeight: "bold",
                          }}
                          onClick={() => window.open(item.link, "_blank")}
                        >
                          {item.titleEs}
                        </Typography>
                        <Typography
                          variant="body2"
                          align="start"
                          mt={2}
                          mb={2}
                          style={{ width: "100%", color: "white" }}
                        >
                          {item.descriptionEs}
                        </Typography>
                        <Link
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          underline="hover"
                          sx={{
                            width: "100%",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {t("home.news.seeMore")}
                        </Link>
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
              <svg
                id="carousel-prev-button"
                className="swiper-button-prev"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="28"
                viewBox="0 0 16 28"
                fill="none"
              >
                <path
                  d="M14.8468 0.999986L2 13.8467L14.8468 26.6935"
                  stroke="#F36E1E"
                  strokeWidth="2"
                />
              </svg>
              <svg
                id="carousel-next-button"
                className="swiper-button-next"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="28"
                viewBox="0 0 16 28"
                fill="none"
              >
                <path
                  d="M14.8468 0.999986L2 13.8467L14.8468 26.6935"
                  stroke="#F36E1E"
                  strokeWidth="2"
                />
              </svg>
            </Box>
          </Box>
        </Container>
      ) : null}
    </Container>
  );
};

export default HomeNews;
