import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Box,
  ButtonGroup,
  Button,
  Pagination,
  Select,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import ReportsRepository from '../../../data/repository/ReportsRepository';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SuccessDialog from '../SuccessDialog';

const CommerceReportsComponent = () => {
  const { roles } = useAuth();
  const [t] = useTranslation();
  const [rows, setRows] = useState([]);
  const [tokenShipmentStatus, setTokenShipmentStatus] = useState([]);
  const [areRowsLoading, setAreRowsLoading] = useState(false);
  const [reportMode, setReportMode] = useState(
    roles[0] === 'ROLE_CELLAR' ? 2 : 0
  );
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageSelected, setPageSelected] = useState();
  const [getTrackingId, setGetTrackingId] = useState('');
  const reportsRepository = new ReportsRepository();

  const handleStatusChange = (event, index) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      shipment: {
        ...updatedRows[index].shipment,
        status: event.target.value
      }
    };
    console.log(updatedRows);
    setRows(updatedRows);
  };
  const getStatusName = (status) => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'sent':
        return 'Enviado';
      case 'finished':
        return 'Finalizado';
      default:
        return 'pending';
    }
  };
  const [success, setSuccess] = useState(null);

  const fetchBenefitsReport = async (page) => {
    setRows([]);
    setAreRowsLoading(true);
    setNumberOfPages(0);
    try {
      const report = await reportsRepository.getBenefitsReport(page);
      setRows(report.rows);
      setNumberOfPages(
        Math.ceil(Number(report.pagination?.count / report.pagination?.limit))
      );
    } catch (error) {
      console.error('Error al obtener reporte:', error);
    }
    setAreRowsLoading(false);
  };

  const fetchTokensReport = async (page) => {
    setRows([]);
    setAreRowsLoading(true);
    setNumberOfPages(0);
    try {
      const report = await reportsRepository.getTokensReport(page);
      setRows(report.rows);
      setNumberOfPages(
        Math.ceil(Number(report.pagination?.count / report.pagination?.limit))
      );
      await fetchTokenShipmentStatus();
    } catch (error) {
      console.error('Error al obtener reporte:', error);
    }
    setAreRowsLoading(false);
  };

  const fetchSalesReport = async (page) => {
    setRows([]);
    setAreRowsLoading(true);
    setNumberOfPages(0);
    try {
      const report = await reportsRepository.getSalesReport(page);
      setRows(report.rows);
      setNumberOfPages(
        Math.ceil(Number(report.pagination?.count / report.pagination?.limit))
      );
    } catch (error) {
      console.error('Error al obtener reporte:', error);
    }
    setAreRowsLoading(false);
  };

  const fetchBenefitPointsReport = async (page) => {
    setRows([]);
    setAreRowsLoading(true);
    setNumberOfPages(0);
    try {
      const report = await reportsRepository.getBenefitPointsReport(page);
      setRows(report.rows);
      setNumberOfPages(
        Math.ceil(Number(report.pagination?.count / report.pagination?.limit))
      );
    } catch (error) {
      console.error('Error al obtener reporte:', error);
    }
    setAreRowsLoading(false);
  };

  const fetchBenefitExchangedReport = async (page) => {
    setRows([]);
    setAreRowsLoading(true);
    setNumberOfPages(0);
    try {
      const report = await reportsRepository.getBenefitExchangedReport(page);
      setRows(report.rows);
      setNumberOfPages(
        Math.ceil(Number(report.pagination?.count / report.pagination?.limit))
      );
    } catch (error) {
      console.error('Error al obtener reporte:', error);
    }
    setAreRowsLoading(false);
  };

  const fetchBenefitTransactionsReport = async (page) => {
    setRows([]);
    setAreRowsLoading(true);
    setNumberOfPages(0);
    try {
      const report = await reportsRepository.getBenefitTransactionsReport(page);
      setRows(report.rows);
      setNumberOfPages(
        Math.ceil(Number(report.pagination?.count / report.pagination?.limit))
      );
    } catch (error) {
      console.error('Error al obtener reporte:', error);
    }
    setAreRowsLoading(false);
  };

  const fetchTokenShipmentStatus = async () => {
    try {
      const shipmentStatus = await reportsRepository.getTokenShipmentStatus();
      setTokenShipmentStatus(shipmentStatus.map((status) => status));
    } catch (error) {
      console.error('Error al obtener los estados:', error);
    }
  };

  const handleTokenShipment = async (e, id, indexRows) => {
    const statusSelected = rows[indexRows].shipment.status;
    const trackingLink = document.getElementById(`track-${indexRows}`).value;

    try {
      await reportsRepository.updateTokenShipment(
        id,
        statusSelected,
        trackingLink
      );
      setSuccess(t('commerceReports.tokenShipmentSuccess'));
    } catch (error) {
      console.error('Error al actualizar los estados:', error);
    }
  };

  const fetchBenefitExcel = async () => {
    if (reportMode === 0) {
      await reportsRepository
        .getBenefitsExcel(pageSelected)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = `benefit_report_${
            new Date().toISOString().replace('T', '_').split('.')[0]
          }.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => null);
    }
    if (reportMode === 1) {
      await reportsRepository
        .getTokensExcel(pageSelected)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = `token_report_${
            new Date().toISOString().replace('T', '_').split('.')[0]
          }.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => null);
    }
    if (reportMode === 2) {
      await reportsRepository
        .getSalesExcel(pageSelected)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = `sales_report_${
            new Date().toISOString().replace('T', '_').split('.')[0]
          }.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => null);
    }
    if (reportMode === 3) {
      await reportsRepository
        .getBenefitPointsExcel(pageSelected)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = `puntos-generados_reporte_${
            new Date().toISOString().replace('T', '_').split('.')[0]
          }.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => null);
    }
    if (reportMode === 4) {
      await reportsRepository
        .getBenefitExchangedExcel(pageSelected)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = `beneficios-consumidos_reporte_${
            new Date().toISOString().replace('T', '_').split('.')[0]
          }.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => null);
    }
    if (reportMode === 5) {
      await reportsRepository
        .getBenefitTransactionsExcel(pageSelected)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = `beneficios-transacciones_reporte_${
            new Date().toISOString().replace('T', '_').split('.')[0]
          }.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => null);
    }
  };

  const clickPaginationReports = async (event, value) => {
    if (pageSelected !== value) {
      setPageSelected(value);
      if (!areRowsLoading) {
        if (reportMode === 0) {
          fetchBenefitsReport(value);
        }
        if (reportMode === 1) {
          fetchTokensReport(value);
        }
        if (reportMode === 2) {
          fetchSalesReport(value);
        }
        if (reportMode === 3) {
          fetchBenefitPointsReport(value);
        }
        if (reportMode === 4) {
          fetchBenefitExchangedReport(value);
        }
        if (reportMode === 5) {
          fetchBenefitTransactionsReport(value);
        }
      }
    }
  };

  useEffect(() => {
    setPageSelected(1);
    if (reportMode === 0) {
      fetchBenefitsReport(1);
    }
    if (reportMode === 1) {
      fetchTokensReport(1);
    }
    if (reportMode === 2) {
      fetchSalesReport(1);
    }
    if (reportMode === 3) {
      fetchBenefitPointsReport(1);
    }
    if (reportMode === 4) {
      fetchBenefitExchangedReport(1);
    }
    if (reportMode === 5) {
      fetchBenefitTransactionsReport(1);
    }
  }, [reportMode]);

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '1rem' }}>
      <Typography
        variant="h1"
        style={{
          color: '#F36E1E',
          padding: '10px',
          textAlign: 'center',
          fontSize: '35px',
          fontWeight: 'bold',
          marginTop: '2rem'
        }}
      >
        {t('commerceReports.title')}
      </Typography>
      <Box
        display="flex"
        justifyContent={'space-between'}
        sx={{ width: '100%', marginTop: { xs: '0', sm: '4rem' } }}
      >
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {roles[0] === 'ROLE_CELLAR' ? (
            <>
              <Button
                onClick={() => setReportMode(2)}
                style={{
                  backgroundColor: reportMode === 2 ? '#F36E1E' : '#878787'
                }}
              >
                {t('commerceReports.report1')}
              </Button>
              <Button
                onClick={() => setReportMode(1)}
                style={{
                  backgroundColor: reportMode === 1 ? '#F36E1E' : '#878787'
                }}
              >
                {t('commerceReports.report2')}
              </Button>
              <Button
                onClick={() => setReportMode(3)}
                style={{
                  backgroundColor: reportMode === 3 ? '#F36E1E' : '#878787'
                }}
              >
                {t('commerceReports.report4')}
              </Button>
              <Button
                onClick={() => setReportMode(4)}
                style={{
                  backgroundColor: reportMode === 4 ? '#F36E1E' : '#878787'
                }}
              >
                {t('commerceReports.report5')}
              </Button>
              <Button
                onClick={() => setReportMode(5)}
                style={{
                  backgroundColor: reportMode === 5 ? '#F36E1E' : '#878787'
                }}
              >
                {t('commerceReports.report6')}
              </Button>
            </>
          ) : null}
          <Button
            onClick={() => setReportMode(0)}
            style={{
              backgroundColor: reportMode === 0 ? '#F36E1E' : '#878787'
            }}
          >
            {t('commerceReports.report3')}
          </Button>
        </ButtonGroup>
        {rows.length ? (
          <CloudDownloadIcon
            color="primary"
            onClick={() => fetchBenefitExcel()}
            style={{
              color: '#F36E1E',
              width: '36px',
              height: '36px',
              padding: '0rem',
              marginLeft: '1rem',
              cursor: 'pointer'
            }}
          />
        ) : null}
      </Box>
      {!rows.length ? (
        <Box
          sx={{
            width: 'calc(100% - 32px)',
            display: 'flex',
            padding: '1rem',
            gap: { xs: '0.5rem', sm: '1rem' },
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="p"
            sx={{
              width: '100%',
              textAlign: 'center',
              color: 'red',
              fontSize: { xs: '12px', sm: '16px' }
            }}
          >
            {areRowsLoading
              ? t('commerceReports.loadingBenefits')
              : t('commerceReports.emptyBenefits')}
          </Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} style={{ marginTop: '3rem' }}>
            <Table aria-label="benefits-table">
              <TableHead>
                {reportMode === 5 ? (
                  <TableRow>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Holder
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Beneficio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Tipo
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Fecha de uso
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Producto
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Precio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      DKF%
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Puntos
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Comercio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      TAX ID
                    </TableCell>
                  </TableRow>
                ) : reportMode === 4 ? (
                  <TableRow>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre comercio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Beneficio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Tipo beneficio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      TAX ID
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Beneficios canjeados
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Valor acción comercio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Puntos usados
                    </TableCell>
                  </TableRow>
                ) : reportMode === 3 ? (
                  <TableRow>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Bodega
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Botella
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Presentación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Unidades
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Puntos
                    </TableCell>
                  </TableRow>
                ) : reportMode === 2 ? (
                  <TableRow>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      TokenId
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Botella
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Bodega
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Precio s/impuestos
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Apellidos
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Email
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Género
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Fecha Nacimiento
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Teléfono
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Residencia
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Código Postal
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Ciudad
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      País
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Método pago
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      OrderId
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      ID Método Pago
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Fecha compra
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Motivos rechazo
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Plataforma cobro
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Canal venta
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número ticket
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre local
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Fecha acreditación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Fecha liberación dinero
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre contraparte
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nickname contraparte
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      E-mail contraparte
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Teléfono contraparte
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Documento contraparte
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Identificador producto
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Descripción operación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Código referencia
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      SKU Producto
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número operación Mercado Pago
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Estado operación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Detalle estado operación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Tipo operación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Valor producto
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Tarifa Mercado Pago
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Comisión uso plataforma terceros
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Costo envío
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Descuento contraparte
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Monto recibido
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Cuotas
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Medio pago
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Monto devuelto
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Operador que devolvió dinero
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número reclamo
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número contracargo
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Plataforma
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número venta Mercado Libre
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número venta negocio online
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número campaña descuento
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre campaña descuento
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Detalle venta
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Mercado Pago Point
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Estado envío
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Domicilio comprador
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Código seguimiento
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Operador cobros Point
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número local
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número caja
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Número caja externo
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Costos financiación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Código descuento
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Tipo cód. descuento
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Valor cód. descuento
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Com. Medio pago
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Com. Drinksify
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Com. Distribuidor
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Gastos internac.
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Shipping
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Com. Bodega
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Neto
                    </TableCell>
                  </TableRow>
                ) : reportMode === 1 ? (
                  <TableRow>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      TokenId
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Bodega
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Botella
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Presentación
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre envío
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Dirección Envío
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Fecha de solicitud
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Estado
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Link Tracking
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Acciones
                    </TableCell>
                  </TableRow>
                ) : reportMode === 0 ? (
                  <TableRow>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      TokenId
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      TaxId Comercio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre beneficio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Tipo
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Valor (DKFs)
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Botella
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Comercio
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Nombre comprador
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Apellidos comprador
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Teléfono comprador
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Email comprador
                    </TableCell>
                    <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>
                      Fecha de activación
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableHead>
              <TableBody>
                {rows.map((row, indexRows) =>
                  reportMode === 5 ? (
                    <TableRow key={row.id}>
                      <TableCell>
                        {row.benefitToken
                          ? row.benefitToken.user?.name
                          : row.token?.user.name}
                      </TableCell>
                      <TableCell>
                        {row.benefitToken ? row.benefitToken.benefit.name : '-'}
                      </TableCell>
                      <TableCell>
                        {row.benefitToken ? row.benefitToken.benefit.type : '-'}
                      </TableCell>
                      <TableCell>
                        {
                          new Date(row.created?.timestamp * 1000)
                            .toLocaleString('en-GB')
                            .split(',')[0]
                        }
                      </TableCell>
                      <TableCell>{row.token?.product.name}</TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>{row.pointsPercentage}</TableCell>
                      <TableCell>{row.value}</TableCell>
                      <TableCell>{row.commerce?.businessName ?? '-'}</TableCell>
                      <TableCell>{row.commerce?.cuit ?? '-'}</TableCell>
                    </TableRow>
                  ) : reportMode === 4 ? (
                    <TableRow key={row.id}>
                      <TableCell>{row.businessName}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.cuit}</TableCell>
                      <TableCell>{row.total}</TableCell>
                      <TableCell>{row.totalPrice}</TableCell>
                      <TableCell>{row.points}</TableCell>
                    </TableRow>
                  ) : reportMode === 3 ? (
                    <TableRow key={row.id}>
                      <TableCell>{row.cellarName}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>Caja {row.bottleQuantity} botella/s</TableCell>
                      <TableCell>{row.units}</TableCell>
                      <TableCell>{row.points}</TableCell>
                    </TableRow>
                  ) : reportMode === 2 ? (
                    <TableRow key={row.id}>
                      <TableCell>{row.tokens?.tokenNumber}</TableCell>
                      <TableCell>{row.product?.name}</TableCell>
                      <TableCell>{row.product?.cellar?.name}</TableCell>
                      <TableCell>{`$${row.price}`} </TableCell>
                      <TableCell>{row.order?.user?.name}</TableCell>
                      <TableCell>{row.order?.user?.surname}</TableCell>
                      <TableCell>{row.order?.user?.email}</TableCell>
                      <TableCell>{row.order?.user?.gender}</TableCell>
                      {row.order?.user?.birthdate?.timestamp ? (
                        <TableCell>
                          {
                            new Date(
                              row.order?.user?.birthdate?.timestamp * 1000
                            )
                              .toLocaleString('en-GB')
                              .split(',')[0]
                          }
                        </TableCell>
                      ) : null}
                      <TableCell>{row.order?.user?.phone}</TableCell>
                      <TableCell>{row.order?.user?.residence}</TableCell>
                      <TableCell>{row.order?.user?.cp}</TableCell>
                      <TableCell>{row.order?.user?.city}</TableCell>
                      <TableCell>{row.order?.user?.country}</TableCell>
                      <TableCell>{row.order?.method}</TableCell>
                      <TableCell>{row.order?.id}</TableCell>
                      <TableCell>{row.order?.externalReference}</TableCell>
                      {row.order?.createdAt?.timestamp ? (
                        <TableCell>
                          {
                            new Date(row.order?.createdAt?.timestamp * 1000)
                              .toISOString()
                              .replace('T', ' ')
                              .split('.')[0]
                          }
                        </TableCell>
                      ) : null}
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{row.order?.discount?.code}</TableCell>
                      <TableCell>{row.order?.discount?.type}</TableCell>
                      <TableCell>
                        {row.discount?.value ? `${row.discount?.value}%` : ''}
                      </TableCell>
                      <TableCell>
                        $
                        {row.priceDetail?.taxes?.gateway_tax
                          ? `${row.priceDetail?.taxes?.gateway_tax}`
                          : 0}
                      </TableCell>
                      <TableCell>
                        $
                        {row.priceDetail?.taxes?.drinksify_tax
                          ? `${row.priceDetail?.taxes?.drinksify_tax}`
                          : 0}
                      </TableCell>
                      <TableCell>
                        $
                        {row.priceDetail?.taxes?.supplier_tax
                          ? `${row.priceDetail?.taxes?.supplier_tax}`
                          : 0}
                      </TableCell>
                      <TableCell>
                        $
                        {row.priceDetail?.taxes?.interment_tax
                          ? `${row.priceDetail?.taxes?.interment_tax}`
                          : 0}
                      </TableCell>
                      <TableCell>
                        $
                        {row.priceDetail?.taxes?.shipping_tax
                          ? `${row.priceDetail?.taxes?.shipping_tax}`
                          : 0}
                      </TableCell>
                      <TableCell>
                        $
                        {row.priceDetail?.taxes?.winery_tax
                          ? `${row.priceDetail?.taxes?.winery_tax}`
                          : 0}
                      </TableCell>
                      <TableCell>
                        {row.priceDetail?.total
                          ? `$${row.priceDetail?.total}`
                          : ''}
                      </TableCell>
                    </TableRow>
                  ) : reportMode === 1 ? (
                    <TableRow key={row.id}>
                      <TableCell>{row.tokenNumber}</TableCell>
                      <TableCell>{row.product?.cellar?.name}</TableCell>
                      <TableCell>{row.product?.name}</TableCell>
                      <TableCell>
                        Caja de{' '}
                        {row.product?.productInfo?.features?.presentation}{' '}
                        botellas
                      </TableCell>
                      <TableCell>{row.shipment?.name}</TableCell>
                      <TableCell>{row.shipment?.address}</TableCell>
                      <TableCell>
                        {row.shipment?.created?.timestamp
                          ? new Date(row.shipment?.created?.timestamp * 1000)
                              .toISOString()
                              .replace('T', ' ')
                              .split('.')[0]
                          : null}
                      </TableCell>
                      <TableCell>
                        {row.shipment && (
                          <Select
                            variant="standard"
                            value={row.shipment.status}
                            onChange={(e) => handleStatusChange(e, indexRows)}
                          >
                            {tokenShipmentStatus?.map((status, index) => (
                              <MenuItem key={index} value={status}>
                                {getStatusName(status)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </TableCell>
                      <TableCell>
                        {row.shipment && (
                          <input
                            type="url"
                            id={`track-${indexRows}`}
                            defaultValue={row.shipment.trackingLink}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.shipment && (
                          <Button
                            onClick={(e) =>
                              handleTokenShipment(e, row.shipment.id, indexRows)
                            }
                          >
                            Actualizar
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ) : reportMode === 0 ? (
                    <TableRow key={row.id}>
                      <TableCell>
                        {row.benefitToken?.token?.tokenNumber}
                      </TableCell>
                      <TableCell>{row.commerce?.cuit}</TableCell>
                      <TableCell>{row.benefitToken?.benefit?.name}</TableCell>
                      <TableCell>{row.benefitToken?.benefit?.type}</TableCell>
                      <TableCell>{Math.abs(row.value)}</TableCell>
                      <TableCell>
                        {row.benefitToken?.token?.product?.name}
                      </TableCell>
                      <TableCell>{row.commerce?.businessName}</TableCell>
                      <TableCell>
                        {row.benefitToken?.token?.user?.name}
                      </TableCell>
                      <TableCell>
                        {row.benefitToken?.token?.user?.surname}
                      </TableCell>
                      <TableCell>
                        {row.benefitToken?.token?.user?.phone}
                      </TableCell>
                      <TableCell>
                        {row.benefitToken?.token?.user?.email}
                      </TableCell>
                      {row.created?.timestamp ? (
                        <TableCell>
                          {
                            new Date(row.created?.timestamp * 1000)
                              .toISOString()
                              .replace('T', ' ')
                              .split('.')[0]
                          }
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" mt={2} justifyContent={'center'}>
            <Pagination
              count={numberOfPages}
              defaultPage={pageSelected}
              siblingCount={2}
              onChange={clickPaginationReports}
            />
          </Box>
        </>
      )}
      <SuccessDialog
        open={!!success}
        onClose={() => setSuccess(null)}
        alertTitle={t('myNFTs.gestionDeTokens')}
        alertMessage={success}
        dark={false}
      />
    </Container>
  );
};

export default CommerceReportsComponent;
