import React from 'react';
import {Typography, DialogContent, Dialog, Card, Box, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MarketplaceBenefitsList = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="p" sx={{width: '100%', color: 'black', fontSize: {xs: '12px', sm: '18px'}}}>
                { props.title }
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', gap: {xs: '0.5rem', sm: '1rem'}, flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                {   props.areBenefitsLoading ?
                    <Typography variant="p" sx={{width: '100%', height: '166px', lineHeight: '176px', textAlign: 'center', color: 'red', fontSize: {xs: '12px', sm: '16px'}}}>
                        {t('marketplaceBenefits.loading')}
                    </Typography>
                    :
                    !props.benefits.length ?
                    <Typography variant="p" sx={{width: '100%', height: '166px', lineHeight: '176px', textAlign: 'center', color: 'red', fontSize: {xs: '12px', sm: '16px'}}}>
                        { !props.ownNft ? t('marketplaceBenefits.emptyBenefits') : t('marketplaceBenefits.emptyOwnBenefits')}
                    </Typography>
                    :
                    props.benefits.map((benefit, index) => (
                    <Card key={benefit.id} elevation={0} onClick={ () => props.setBenefitsModal({ ...benefit, redeemable: props.ownNft }) } sx={{
                        width: {xs: '100%', sm: '268px'},
                        backgroundColor: 'transparent',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        border: '1px solid rgba(0, 0, 0, 0.25)',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        alignSelf: 'stretch',
                        display: 'flex', gap: '0.25rem', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'
                    }}>
                        <CardMedia
                            component="img"
                            style={{
                                width: '132px',
                                height: '88px',
                                padding: '1rem',
                                objectFit: 'contain'
                            }}
                            image={benefit?.image}
                            alt={`Product ${index}`}
                        />
                        <Box sx={{margin: '0rem', padding: '0.5rem 1rem', display: 'flex', flexDirection: 'row', width: 'calc(100% - 2rem)', rowGap: '0.5rem', justifyContent: 'center', alignItems: 'start', minHeight: '51px', background: '#F0F0F0' }}>
                            <Typography variant="h6" sx={{margin: '0rem !important', width: '100%', color: 'black', fontSize: '16px', fontWeight: '500'}}>
                                {benefit?.name} <br/> <b>{benefit?.pricePoints ? `${benefit?.pricePoints} DKFs` : ''}</b>
                            </Typography>
                            { props.ownNft ?
                                <Box sx={{margin: '0rem', padding: '0rem', display: 'flex', gap: '0rem', justifyContent: 'end', alignItems: 'center'}}>
                                    { benefit.state === 2 ? 
                                        <svg onClick={ (e) => {props.setConfirmModal(benefit); e.stopPropagation()} } width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="OBJECTS">
                                                <g id="Group">
                                                    <path id="Vector" d="M17.2705 22.6322C17.4566 22.8401 17.7228 22.9568 18 22.9568C18.2772 22.9568 18.5435 22.8401 18.7295 22.6322L23.0991 17.7921C23.3581 17.5039 23.4237 17.0918 23.2669 16.7343C23.1101 16.3805 22.7563 16.1508 22.3696 16.1508H20.3052V10.8584C20.3052 10.3149 19.8675 9.8772 19.324 9.8772H16.6724C16.1289 9.8772 15.6912 10.3149 15.6912 10.8584V16.1508H13.6268C13.2401 16.1508 12.8863 16.3805 12.7295 16.7343C12.5727 17.0881 12.6383 17.5039 12.8973 17.7921L17.2669 22.6322H17.2705Z" fill="#F36E1E"/>
                                                    <path id="Vector_2" d="M26.0645 24.1824H9.93557C9.4067 24.1824 8.97266 24.6128 8.97266 25.1453C8.97266 25.6778 9.40305 26.1082 9.93557 26.1082H26.0645C26.5933 26.1082 27.0237 25.6778 27.0237 25.1453C27.0237 24.6128 26.5933 24.1824 26.0645 24.1824Z" fill="#F36E1E"/>
                                                </g>
                                            </g>
                                        </svg>
                                        : null
                                    }
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g clipPath="url(#clip0_824_418)">
                                            <path d="M3.08476 5.83197C3.79942 5.84186 4.4359 6.06887 4.99463 6.51129C5.27039 6.72971 5.54826 6.76196 5.86353 6.61061C7.70415 5.72835 9.54689 4.84995 11.3892 3.9707C11.7113 3.81678 11.8617 3.56611 11.8596 3.20194C11.8498 1.54447 12.9367 0.272241 14.561 0.0288872C16.182 -0.214037 17.8004 1.05304 17.9784 2.70406C18.1658 4.44107 17.1103 5.88915 15.4184 6.18668C14.6171 6.3277 13.8676 6.15056 13.1963 5.68406C12.8942 5.47425 12.6065 5.46049 12.2806 5.61742C10.4158 6.51516 8.54798 7.40689 6.67974 8.29689C6.3734 8.44308 6.16903 8.65461 6.16265 9.01362C6.15628 9.37994 6.35173 9.60481 6.66317 9.76518C8.43411 10.6775 10.2038 11.5925 11.9688 12.5169C12.3537 12.7185 12.6779 12.6983 13.025 12.4236C14.0346 11.6247 15.4146 11.5564 16.5108 12.2267C17.5841 12.8832 18.1437 14.152 17.9287 15.4397C17.7247 16.6608 16.7751 17.6527 15.5476 17.9266C13.7278 18.3324 11.9598 16.9914 11.8413 15.1061C11.8171 14.717 11.6607 14.4573 11.3102 14.2775C9.46149 13.3304 7.61875 12.3711 5.77643 11.4119C5.45819 11.2464 5.17224 11.2709 4.88544 11.4846C3.87378 12.2387 2.48014 12.2744 1.41282 11.5813C0.371839 10.9058 -0.182216 9.60868 0.0540223 8.40094C0.31023 7.09259 1.27345 6.11832 2.5375 5.89431C2.71808 5.8625 2.90205 5.85218 3.08433 5.83197H3.08476Z" fill="#F36E1E"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_824_418">
                                            <rect width="18" height="18" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>*/}
                                </Box>
                                : null
                            }
                        </Box>
                    </Card>
                ))}
            </Box>
        </>
    );
}

export default MarketplaceBenefitsList;
