import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ActivateUserAccount from "./presentation/functional/ActivateUserAccount";
import Logout from "./presentation/functional/Logout";
import RouteAnonymousUser from "./presentation/routing/RouteAnonymousUser";
import AgeVerificationDialog from "./presentation/components/AgeVerificationDialog";
import { useAuth } from "./presentation/contexts/AuthContext";
import { CartProvider } from "./presentation/contexts/CartContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { drinksifyPages } from "./components";
import AuthDialog from "./presentation/components/AuthDialog";
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        sizeLarge: {
          fontSize: "20px",
        },
      },
    },
  },
});

function App() {
  const { isAuthenticated, roles, authModal, setAuthModal } = useAuth();

  const handleAuthModalClose = () => {
    setAuthModal({ state: 0, message: "" });
    sessionStorage.removeItem("expired");
    sessionStorage.removeItem("expiredMsg");
  };

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <Router>
          <CartProvider>
            <div className="App">
              <Routes>
                {drinksifyPages.map(
                  (
                    {
                      exact,
                      path,
                      theme,
                      neededAuth,
                      component: Component,
                      layout: Layout,
                    },
                    index
                  ) => (
                    <Route
                      key={index}
                      exact={exact}
                      path={path}
                      element={
                        isAuthenticated || isAuthenticated === neededAuth ? (
                          <Layout main={<Component />} theme={theme} />
                        ) : (
                          <Navigate replace to={"/"} />
                        )
                      }
                    />
                  )
                )}
                <Route
                  path="/activate_account"
                  element={
                    <RouteAnonymousUser>
                      <ActivateUserAccount />
                    </RouteAnonymousUser>
                  }
                />
                <Route path="/logout" element={<Logout />} />
              </Routes>
              <AgeVerificationDialog />
              <AuthDialog
                open={authModal.state}
                onClose={handleAuthModalClose}
                alertTitle={"expired.title"}
                alertMessage={authModal.message}
                dark={true}
              />
            </div>
          </CartProvider>
        </Router>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
