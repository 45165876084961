import api from '../api';
import apiToken from '../apiToken';

class UserRepository {
  async register(user) {
    try {
      const response = await api.post('/users/register', {
        name: user.name,
        surname: user.surname,
        gender: user.gender,
        email: user.email,
        phone: user.phone,
        residence: user.residence,
        cp: user.cp,
        country: user.country,
        city: user.city,
        password: user.password,
        birthdate: user.birthdate,
        token: user.token,
        bankAccount: user.bankAccount,
        bankAlias: user.bankAlias,
        cryptoWallet: user.cryptoWallet,
        cryptoNet: user.cryptoNet
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async registerCommerce(commerce) {
    try {
      const response = await api.post('/users/register/commerce', {
        businessName: commerce.businessName,
        cuit: commerce.tax,
        cellars: commerce.cellars,
        email: commerce.email,
        phone: commerce.phone,
        residence: commerce.residence,
        cp: commerce.cp,
        country: commerce.country,
        city: commerce.city,
        password: commerce.password,
        category: commerce.category
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async registerWinery(winery) {
    try {
      const response = await api.post('/users/register/cellar', {
        name: winery.name,
        email: winery.email,
        phone: winery.phone,
        residence: winery.residence,
        cp: winery.cp,
        country: winery.country,
        city: winery.city,
        password: winery.password
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async activateAccount(token, uid) {
    try {
      const response = await api.post('/users/activate', {
        token: token,
        uid: uid
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async resendActivation(email) {
    try {
      const response = await api.post('/users/restore/token', {
        email: email
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async sendRecoveryPassword(requestPassword) {
    try {
      const response = await api.post('/users/request/reset/password', {
        email: requestPassword.email
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async changePassword(uid, token, password) {
    try {
      const response = await api.post('/users/reset/password', {
        uid: uid,
        token: token,
        password: password
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async login(user) {
    try {
      const response = await api.post('/users/login', {
        email: user.email,
        password: user.password
      });
      return { success: true, jwt: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      let result = {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };

      if (error.response) {
        result.code = error.response.data.code;
      }

      return result;
    }
  }

  async googleLogin({ token, firstName, lastName }) {
    try {
      const response = await api.post('/users/login/google', {
        token,
        firstName,
        lastName
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error sending message:', error);
      let result = {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };

      if (error.response) {
        result.code = error.response.data.code;
      }

      return result;
    }
  }

  async findData(language) {
    try {
      const response = await apiToken.get('/user/data', {
        headers: {
          'x-locale': language
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserData(userData) {
    try {
      const response = await apiToken.post('/user/update/data', {
        name: userData.name,
        surname: userData.surname,
        phone: userData.phone,
        residence: userData.residence,
        cp: userData.cp,
        country: userData.country,
        city: userData.city,
        bankAccount: userData.bankAccount,
        bankAlias: userData.bankAlias,
        cryptoWallet: userData.cryptoWallet,
        cryptoNet: userData.cryptoNet
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error updating user data:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async updateCommerceData(userData) {
    try {
      const response = await apiToken.post('/commerce/update/data', {
        phone: userData.phone,
        residence: userData.residence,
        cp: userData.cp,
        country: userData.country,
        city: userData.city,
        businessName: userData.businessName,
        cuit: userData.cuit,
        category: userData.category
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error updating user data:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async updateWineryData(userData) {
    try {
      const response = await apiToken.post('/cellar/update/data', {
        phone: userData.phone,
        residence: userData.residence,
        cp: userData.cp,
        country: userData.country,
        city: userData.city,
        name: userData.name,
        cuit: userData.cuit
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error updating user data:', error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }
}

export default UserRepository;
