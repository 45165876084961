import React, {useState, useEffect} from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Grid, TextField, Button, Select, OutlinedInput, MenuItem, CircularProgress} from '@mui/material';
import UserRepository from "../../../data/repository/UserRepository";
import { UserData, CommerceData, WineryData } from "../../../core/entity/UserData";
import SuccessDialog from "../SuccessDialog";
import ErrorDialog from "../ErrorDialog";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../contexts/AuthContext";
import CategoriesRepository from '../../../data/repository/CategoriesRepository';

const UserDataComponent = () => {
    const [t, i18n] = useTranslation();

    const { isAuthenticated, roles } = useAuth();

    const [user, setUser] = useState(roles[0] === "ROLE_USER" ? {
        name: '',
        surname: "",
        email: '',
        phone: '',
        residence: '',
        cp: '',
        country: '',
        city: '',
        metamaskAddress: '',
        bankAccount: '',
        bankAlias: '',
        cryptoWallet: '',
        cryptoNet: '',
    } : roles[0] === "ROLE_COMMERCE" ? {
        email: '',
        phone: '',
        residence: '',
        cp: '',
        country: '',
        city: '',
        businessName: '',
        cuit: '',
        category: ''
    } : roles[0] === "ROLE_CELLAR" ? {
        email: '',
        phone: '',
        residence: '',
        cp: '',
        country: '',
        city: '',
        name: '',
    }
    : null);

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [areCategoriesLoading, setAreCategoriesLoading] = useState(false);
    const [userisLoading, setUserIsLoading] = useState(true);

    const userRepository = new UserRepository();
    const categoriesRepository = new CategoriesRepository();

    const tableFields = [
        { label: t("myProfile.email"), value: user?.email, error: errors?.email, id: "email" },
        { label: t("myProfile.nombre"), value: user?.name, error: errors?.name, id: "name" },
        { label: t("myProfile.surname"), value: user?.surname, error: errors?.surname, id: "surname" },
        { label: t("myProfile.residencia"), value: user?.residence, error: errors?.residence, id: "residence" },
        { label: t("myProfile.cp"), value: user?.cp, error: errors?.cp, id: "cp" },
        { label: t("myProfile.country"), value: user?.country, error: errors?.country, id: "country" },
        { label: t("myProfile.city"), value: user?.city, error: errors?.city, id: "city" },
        { label: t("myProfile.razonSocial"), value: user?.businessName, error: errors?.businessName, id: "businessName" },
        { label: t("myProfile.identificacionFiscal"), value: user?.cuit, error: errors?.cuit, id: "cuit" },
        { label: t("myProfile.category"), value: user?.category, error: errors?.category, id: "category" },
        { label: t("myProfile.direccionMetamask"), value: user?.metamaskAddress, error: errors?.metamaskAddress, id: "metamaskAddress" },
        { label: t("myProfile.bankAccount"), value: user?.bankAccount, error: errors?.bankAccount, id: "bankAccount" },
        { label: t("myProfile.bankAlias"), value: user?.bankAlias, error: errors?.bankAlias, id: "bankAlias" },
        { label: t("myProfile.cryptoWallet"), value: user?.cryptoWallet, error: errors?.cryptoWallet, id: "cryptoWallet" },
        { label: t("myProfile.cryptoNet"), value: user?.cryptoNet, error: errors?.cryptoNet, id: "cryptoNet" },
        { label: t("myProfile.telefono"), value: user?.phone, error: errors?.phone, id: "phone" },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setUserIsLoading(true);
            try {
                const data = await userRepository.findData(i18n.language);
                setUser(roles[0] === "ROLE_USER" ? {
                    name: data.name,
                    surname: data.surname,
                    email: data.email,
                    phone: data.phone,
                    residence: data.residence,
                    cp: data.cp,
                    country: data.country,
                    city: data.city,
                    metamaskAddress: data.metamaskAddress,
                    bankAccount: data.bankAccount,
                    bankAlias: data.bankAlias,
                    cryptoWallet: data.cryptoWallet,
                    cryptoNet: data.cryptoNet,
                } : roles[0] === "ROLE_COMMERCE" ? {
                    email: data.email,
                    phone: data.phone,
                    residence: data.residence,
                    cp: data.cp,
                    country: data.country,
                    city: data.city,
                    businessName: data.commerce?.businessName,
                    cuit: data.commerce?.cuit,
                    category: data.commerce?.category?.id
                } : roles[0] === "ROLE_CELLAR" ? {
                    email: data.email,
                    phone: data.phone,
                    residence: data.residence,
                    cp: data.cp,
                    country: data.country,
                    city: data.city,
                    name: data.name,
                }
                : null);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
            setUserIsLoading(false);
        };

        fetchData();
    }, [i18n.language]);

    const handleSubmit = async () => {
        if (roles[0] === "ROLE_USER") {
            const userDataValidator = new UserData(user);
            const validationErrors = userDataValidator.validateUserData(user);
    
            setErrors(validationErrors);
    
            const hasErrors = Object.values(validationErrors).some(error => error);
    
            if (!hasErrors) {
                const updateResult = await userRepository.updateUserData(user);
    
                if (updateResult.success) {
                    setSuccess(updateResult.data.message);
                } else {
                    setError(updateResult.message === "register.error.00001" ? t('registerForm.noValidPhone') : updateResult.message === "register.error.00002" ? t('myProfile.error2') : updateResult.message === "register.error.00003" ? t('myProfile.error3') : "Error: " + updateResult.message);
                }
            }
        } else if (roles[0] === "ROLE_COMMERCE") {
            const commerceDataValidator = new CommerceData(user);
            const validationErrors = commerceDataValidator.validateCommerceData(user);
    
            setErrors(validationErrors);
    
            const hasErrors = Object.values(validationErrors).some(error => error);
    
            if (!hasErrors) {
                const updateResult = await userRepository.updateCommerceData(user);
    
                if (updateResult.success) {
                    setSuccess(updateResult.data.message);
                } else {
                    setError(updateResult.message === "register.error.00001" ? t('registerForm.noValidPhone') : "Error: " + updateResult.message);
                }
            }
        } else if (roles[0] === "ROLE_CELLAR") {
            const wineryDataValidator = new WineryData(user);
            const validationErrors = wineryDataValidator.validateWineryData(user);
    
            setErrors(validationErrors);
    
            const hasErrors = Object.values(validationErrors).some(error => error);
    
            if (!hasErrors) {
                const updateResult = await userRepository.updateWineryData(user);
    
                if (updateResult.success) {
                    setSuccess(updateResult.data.message);
                } else {
                    setError(updateResult.message === "register.error.00001" ? t('registerForm.noValidPhone') : "Error: " + updateResult.message);
                }
            }
        } else {
            return null;
        }
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setUser(prevState => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        const fetchCategories = async () => {
            setAreCategoriesLoading(true);
            try {
                const response = await categoriesRepository.getCategories(i18n.language);
                setCategories(response.map(category => category));
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
            setAreCategoriesLoading(false);
        }
        fetchCategories();
    }, [i18n.language]);

    return (
        <Container maxWidth="xl" sx={{ paddingBottom: '1rem' }}>
            <Typography variant="h1" style={{ color: '#F36E1E', padding: '10px', textAlign: 'center', fontSize: '35px', fontWeight: 'bold', marginTop: '2rem' }}>
                {t('myProfile.miPerfil')}
            </Typography>
            { userisLoading || areCategoriesLoading ?
                <div style={{display: 'flex', padding: '1rem', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress sx={{ color: '#F36E1E'}} />
                </div>
                :
                <>
                    <Typography variant="body1" style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '3rem' }}>
                        {t('myProfile.datosCuenta')}
                    </Typography>

                    <TableContainer component={Paper} style={{ marginTop: '1rem', borderBottom: 'none', boxShadow: 'none' }}>
                        <Table>
                            <TableBody>
                                { tableFields.map((field, index) => {
                                    if (roles[0] === "ROLE_USER" && (field.id === "businessName" || field.id === "cuit" || field.id === "category" || field.id === "metamaskAddress")) {
                                        return null;
                                    } else if (roles[0] === "ROLE_COMMERCE" && (field.id === "name" || field.id === "surname" || field.id === "metamaskAddress" || field.id === "bankAccount" || field.id === "cryptoWallet" || field.id === "cryptoNet" || field.id === "bankAlias")) {
                                        return null;
                                    } else if (roles[0] === "ROLE_CELLAR" && (field.id === "category" || field.id === "surname" || field.id === "metamaskAddress" || field.id === "businessName" || field.id === "cuit" || field.id === "bankAccount" || field.id === "cryptoWallet" || field.id === "cryptoNet" || field.id === "bankAlias")) {
                                        return null;
                                    } else {
                                        return (
                                            <TableRow key={field.id} style={{ borderBottom: 'none' }}>
                                                <TableCell style={{ borderBottom: 'none', padding: 8 }}>{field.label}</TableCell>
                                                <TableCell style={{ borderBottom: 'none', padding: 8 }}>
                                                    {   field.id === "email" ?
                                                        <Typography variant="p" style={{ fontSize: '16px', fontWeight: '400', marginTop: '0rem', lineHeight: '32px' }}>
                                                            {field.value}
                                                        </Typography> 
                                                        :
                                                        field.id === "category" ?
                                                        categories.length ?
                                                        <Select
                                                            fullWidth
                                                            name={field.id}
                                                            value={field.value}
                                                            onChange={handleSelectChange}
                                                            input={
                                                                <OutlinedInput
                                                                    style={{
                                                                        borderRadius: '55px'
                                                                    }}
                                                                />
                                                            }
                                                            helperText={t(field.error)} error={Boolean(field.error)}
                                                        >
                                                            { categories.map((category, index) => (
                                                                <MenuItem key={ category.id } value={ category.id }>{ category.name }</MenuItem>
                                                            ))}
                                                        </Select>
                                                        : null
                                                        :
                                                        <>
                                                            <TextField
                                                                fullWidth
                                                                variant="standard"
                                                                id={field.id}
                                                                name={field.id}
                                                                value={field.value}
                                                                onChange={e => setUser({ ...user, [field.id]: e.target.value })}
                                                                error={Boolean(field.error)}
                                                                helperText={t(field.error)}
                                                            />
                                                        </>
                                                    }
                                                    { field.id === "phone" ?
                                                        <Typography variant="h3" style={{margin: '0rem', fontSize: '13px'}}>
                                                            {t('registerForm.phoneText')} 
                                                        </Typography>
                                                        : null
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" onClick={handleSubmit} className={'btn-gradient'} style={{ marginTop: '2rem' }} type="submit">
                        {t("myProfile.guardar")}
                    </Button>
                    { roles[0] === "ROLE_USER" ?
                        <Typography variant="h6" style={{marginTop: '1rem', fontSize: '13px'}}>
                            {t("myProfile.phoneAlert")}
                        </Typography> : null
                    }
                </>
            }


            <SuccessDialog
                open={!!success}
                onClose={() => setSuccess(null)}
                alertTitle={t("myProfile.notificacion")}
                alertMessage={success}
                dark={false}
            />

            <ErrorDialog
                open={!!error}
                onClose={() => setError(null)}
                alertTitle={t("myProfile.error")}
                alertMessage={error}
                dark={false}
            />
        </Container>
    );
}

export default UserDataComponent;