const MIN_NAME_LENGTH = 2;

export class UserData {
    constructor(userData) {
        this.validateUserData(userData);
        Object.assign(this, userData);
    }

    validateUserData({ name, surname, email, phone, residence, cp, country, city, metamaskAddress }) {
        let errors = {};

        const nameError = this.isValidName(name);
        if (nameError) {
            errors.name = nameError;
        }

        const surnameError = this.isValidName(surname);
        if (surnameError) {
            errors.surname = surnameError;
        }

        const emailError = this.isValidEmail(email);
        if (emailError) {
            errors.email = emailError;
        }

        const phoneError = this.isValidPhone(phone);
        if (phoneError) {
            errors.phone = phoneError;
        }

        const residenceError = this.isValidResidence(residence);
        if (residenceError) {
            errors.residence = residenceError;
        }

        const cpError = this.isValidResidence(cp);
        if (cpError) {
            errors.cp = cpError;
        }

        const countryError = this.isValidResidence(country);
        if (countryError) {
            errors.country = countryError;
        }

        const cityError = this.isValidResidence(city);
        if (cityError) {
            errors.city = cityError;
        }

        const metamaskAddressError = this.isValidMetamaskAddress(metamaskAddress);
        if (metamaskAddressError) {
            errors.metamaskAddress = metamaskAddressError;
        }

        return errors;
    }

    isValidName(name) {
        if (!name) return 'registerForm.emptyField';
        if (name.length < MIN_NAME_LENGTH) return 'registerForm.noValidField';
        return null;
    }

    isValidEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) return 'registerForm.emptyField';
        if (!regex.test(email)) return 'registerForm.noValidEmail';
        return null;
    }

    isValidPhone(phone) {
        const regex = /^[+][(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,13}$/;
        if (!phone) return 'registerForm.emptyField';
        if (!regex.test(phone)) return 'registerForm.noValidPhone';
        return null;
    }

    isValidResidence(residence) {
        if (!residence) return 'registerForm.emptyField';
        return null;
    }

    isValidMetamaskAddress(metamaskAddress) {
        if (metamaskAddress) {
            const regex = /^0x[a-fA-F0-9]{40}$/;
            if (!regex.test(metamaskAddress)) return "Dirección de Metamask no válida.";
        }
        return null;
    }
}

export class CommerceData {
    constructor(commerceData) {
        this.validateCommerceData(commerceData);
        Object.assign(this, commerceData);
    }

    validateCommerceData({ email, phone, residence, cp, country, city, businessName, cuit, category }) {
        let errors = {};

        const emailError = this.isValidEmail(email);
        if (emailError) {
            errors.email = emailError;
        }

        const phoneError = this.isValidPhone(phone);
        if (phoneError) {
            errors.phone = phoneError;
        }

        const residenceError = this.isValidResidence(residence);
        if (residenceError) {
            errors.residence = residenceError;
        }

        const cpError = this.isValidResidence(cp);
        if (cpError) {
            errors.cp = cpError;
        }

        const countryError = this.isValidResidence(country);
        if (countryError) {
            errors.country = countryError;
        }

        const cityError = this.isValidResidence(city);
        if (cityError) {
            errors.city = cityError;
        }

        const businessNameError = this.isValidBusinessName(businessName);
        if (businessNameError) {
            errors.businessName = businessNameError;
        }

        const cuitError = this.isValidCuit(cuit);
        if (cuitError) {
            errors.cuit = cuitError;
        }

        const categoryError = this.isValidResidence(category);
        if (categoryError) {
            errors.category = categoryError;
        }

        return errors;
    }

    isValidBusinessName(businessName) {
        if (!businessName) return 'registerForm.emptyField';
        return null;
    }

    isValidCuit(cuit) {
        if (!cuit) return 'registerForm.emptyField';
        return null;
    }

    isValidEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) return 'registerForm.emptyField';
        if (!regex.test(email)) return 'registerForm.noValidEmail';
        return null;
    }

    isValidPhone(phone) {
        const regex = /^[+][(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,13}$/;
        if (!phone) return 'registerForm.emptyField';
        if (!regex.test(phone)) return 'registerForm.noValidPhone';
        return null;
    }

    isValidResidence(residence) {
        if (!residence) return 'registerForm.emptyField';
        return null;
    }

}

export class WineryData {
    constructor(wineryData) {
        this.validateWineryData(wineryData);
        Object.assign(this, wineryData);
    }

    validateWineryData({ email, phone, residence, cp, country, city, name }) {
        let errors = {};

        const emailError = this.isValidEmail(email);
        if (emailError) {
            errors.email = emailError;
        }

        const phoneError = this.isValidPhone(phone);
        if (phoneError) {
            errors.phone = phoneError;
        }

        const residenceError = this.isValidResidence(residence);
        if (residenceError) {
            errors.residence = residenceError;
        }

        const cpError = this.isValidResidence(cp);
        if (cpError) {
            errors.cp = cpError;
        }

        const countryError = this.isValidResidence(country);
        if (countryError) {
            errors.country = countryError;
        }

        const cityError = this.isValidResidence(city);
        if (cityError) {
            errors.city = cityError;
        }

        const cuitError = this.isValidName(name);
        if (cuitError) {
            errors.cuit = cuitError;
        }

        return errors;
    }

    isValidName(name) {
        if (!name) return 'registerForm.emptyField';
        return null;
    }

    isValidEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) return 'registerForm.emptyField';
        if (!regex.test(email)) return 'registerForm.noValidEmail';
        return null;
    }

    isValidPhone(phone) {
        const regex = /\+\d{1,20}/;
;
        if (!phone) return 'registerForm.emptyField';
        if (!regex.test(phone)) return 'registerForm.emptyPhone';
        return null;
    }

    isValidResidence(residence) {
        if (!residence) return 'registerForm.emptyField';
        return null;
    }

}


