import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import IconAccessibility from '../../assets/images/icon-accesibilidad.png';
import IconBenefits from '../../assets/images/icon-beneficios.png';
import IconTransfer from '../../assets/images/icon-transferir.png';
import IconWineNft from '../../assets/images/icon-winenft.png';
import { useTranslation } from 'react-i18next';

const HomeInfo1 = () => {
    const { t } = useTranslation();
    const images = [
        { src: IconAccessibility, title: t('home.section2.column1.title'), paragraph: t('home.section2.column1.p') },
        { src: IconBenefits, title: t('home.section2.column2.title'), paragraph: t('home.section2.column2.p') },
        { src: IconTransfer, title: t('home.section2.column3.title'), paragraph: t('home.section2.column3.p') },
        { src: IconWineNft, title: t('home.section2.column4.title'), paragraph: t('home.section2.column4.p') }
    ];
    return (
        <Container maxWidth="xl" mt={15} mb={15}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{color: 'white', fontSize: { xs: '20px', sm: '40px' }, fontWeight: 'bold'}} align="center">
                        {t('home.section2.title')}
                    </Typography>
                </Grid>

                <Grid item xs={1} sx={{display: {xs: 'none', sm: 'block'}}}></Grid>
                <Grid item xs={12} sm={10}>
                    <Typography variant="body1" style={{color: 'white'}} align="center">
                        {t('home.section2.subtitle')}
                    </Typography>
                </Grid>
                <Grid item xs={1} sx={{display: {xs: 'none', sm: 'block'}}}></Grid>

                {images.map((image, index) => (
                    <Grid item xs={12} sm={3} key={index} sx={{minHeight: "348px", alignSelf: "stretch"}}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img src={image.src} alt={`Icon ${index}`} style={{objectFit: 'cover', minHeight: "86px"}} />
                            <Typography variant="h5" gutterBottom align="center" mt={3} style={{color: 'white', fontWeight: 'bold'}}>
                                {image.title}
                            </Typography>
                            <Typography variant="body2" align="center" mt={2} style={{color: 'white'}}>
                                {image.paragraph}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default HomeInfo1;
