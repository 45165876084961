import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import {Button, ButtonGroup, Typography,Container, Grid, Card, Box, CardMedia, CircularProgress, DialogContent, Dialog, FormControl, InputLabel, OutlinedInput, Select, MenuItem } from '@mui/material';
import CellarRepository from "../../../data/repository/CellarRepository";
import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import BenefitsRepository from '../../../data/repository/BenefitsRepository';
import MarketplaceBenefitsList from './MarketplaceBenefitsList';
import ErrorDialog from "../ErrorDialog";

const MarketplaceBenefitsComponent = () => {
    const [t, i18n] = useTranslation();

    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    /*useEffect(() => {
      setTimeout(() => {
        const param = searchParams.get('product');
        if (param) {
          searchParams.delete('product');
          setSearchParams(searchParams);
        }
      }, 500);
    }, [])*/

    const { isAuthenticated, setIsAuthenticated, roles } = useAuth();
    const [benefits, setBenefits] = useState([]);
    const [areBenefitsLoading, setAreBenefitsLoading] = useState(false);
    const [ownBenefits, setOwnBenefits] = useState([]);
    const [areOwnBenefitsLoading, setAreOwnBenefitsLoading] = useState(false);
    const [isBenefitRedeeming, setIsBenefitRedeeming] = useState(false);
    const [benefitsView, setBenefitsView] = useState(1);
    const [benefitsModal, setBenefitsModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [cellars, setCellars] = useState([]);
    const [loading, setLoading] = useState(false);
    const [benefitCategories, setBenefitCategories] = useState([]);
    const productInUrl = searchParams.get('product');
    const cellarInUrl = searchParams.get('cellar');
    const typeInUrl = searchParams.get('type');
    const [error, setError] = useState(null);

    const cellarRepository = new CellarRepository();
    const benefitsRepository = new BenefitsRepository();
    const [filters, setFilters] = useState({
        cellarId: cellarInUrl ?? null,
        productId: productInUrl ? productInUrl : null,
        type: typeInUrl ?? 'occasion',
        benefitCategoryId: null
    });

    const [exchangeMode, setExchangeMode] = useState(0);

    const isMobile = window.innerWidth <= 768;
    const [drawerOpen, setDrawerOpen] = useState(!isMobile);
    
    const fetchBenefits = async (filtersObject) => {
        setAreBenefitsLoading(true);
        try {
            const response = await benefitsRepository.getBenefitsFiltered(filtersObject, i18n.language);
            setBenefits(response.map(benefits => benefits));
        } catch (error) {
            console.error("Error fetching benefits:", error);
        }
        setAreBenefitsLoading(false);
    }

    const fetchOwnBenefits = async (filtersObject) => {
        setAreOwnBenefitsLoading(true);
        try {
            const response = await benefitsRepository.getOwnBenefitsFiltered(filtersObject, i18n.language);
            setOwnBenefits(response);
        } catch (error) {
            console.error("Error fetching own benefits:", error);
        }
        setAreOwnBenefitsLoading(false);
    }

    const fetchBenefitCategories = async () => {
        try {
            const response = await benefitsRepository.getBenefitCategories();
            setBenefitCategories(response.rows.map(benefitCategory => benefitCategory));
        } catch (error) {
            console.error("Error fetching benefit categories:", error);
        }
    }
    
    const redeemBenefit = async (nft, benefit) => {
        setIsBenefitRedeeming(true);
        try {
            await benefitsRepository.redeemBenefit(nft, benefit);
        } catch (error) {
            setError(error === "redeemBenefit.error.00001" ? t('home.benefits.redeem.error1') 
                : error === "redeemBenefit.error.00002" ? t('home.home.benefits.redeem.error2') 
                : error === "redeemBenefit.error.00003" ? t('home.benefits.redeem.error3')
                : error === "redeemBenefit.error.00004" ? t('home.benefits.redeem.error4')
                : "Error: " + error
            )

            console.error("Error redeeming benefit:", error);
        }
        setConfirmModal(false);
        fetchOwnBenefits(filters);
        setTimeout(() => {
            setIsBenefitRedeeming(false);
        }, 2000);
    }

    useEffect(() => {
        if (!productInUrl) {
            const fetchCellars = async () => {
                try {
                    const response = await cellarRepository.getCellarList();
                    setCellars(response.map(cellar => cellar));
                } catch (error) {
                    console.error("Error fetching cellars:", error);
                }
            }
            setLoading(true);
            fetchBenefitCategories();
            fetchCellars();
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!state?.tokenId) {
            fetchBenefits(filters);
        }
    }, [filters, i18n.language]);

    useEffect(() => {
        if (isAuthenticated && roles[0] === "ROLE_USER" && ((!state?.tokenId && !productInUrl) || (state?.tokenId && productInUrl))) {
            fetchOwnBenefits(filters);
        }
    }, [filters, isAuthenticated, i18n.language]);

    const handleFilterChange = (filterType, value) => {
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [filterType]: value ? value : null
            };

            //fetchBenefits(updatedFilters);
            //fetchOwnBenefits(updatedFilters);
            return updatedFilters;
        });
    }

    const handleCardClick = (productId) => {
        if (exchangeMode) {
            navigate(`/token/detail/${productId}`);
        } else {
            navigate(`/product/detail/${productId}`);
        }
    };

    const handleDate = (date) => {
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        
        
        const formattedToday = dd + '/' + mm + '/' + yyyy;
        
        return formattedToday;
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevState => ({ ...prevState, [name]: value }));
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleBenefitView = (view) => {
        setBenefitsView(view);
        setFilters({
            type: view === 1 ? 'occasion' : 'points'
        })
    };


    return (
        <Container id="marketplace-benefits" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '2rem' }} maxWidth={'xl'}>
            <Typography variant="h3" sx={{marginTop: '2rem', marginBottom: '1rem', color: '#F36E1E', fontSize: {xs: '22px', sm: '32px'}, fontWeight: 'bold'}} align="center">
                {t('marketplaceBenefits.title')}
            </Typography>
            <Grid container>
            {loading ? (
                <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                        <CircularProgress size={80} style={{ color: '#F36E1E' }} />
                    </div>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', width: '100%', gap: {xs: '1.5rem', sm: '3rem'}, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    { productInUrl ? null :
                        <>
                            <Box sx={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: { xs: 'center', sm: 'start' }, alignItems: 'center', marginTop: { xs: '2rem', sm: '4rem' } }}>
                                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                                    <Button
                                        onClick={() => handleBenefitView(1)}
                                        style={{backgroundColor: benefitsView === 1 ? '#F36E1E' : '#878787', minWidth: '120px'}}
                                    >{t('marketplaceBenefits.box1')}</Button>
                                    <Button
                                        onClick={() => handleBenefitView(2)}
                                        style={{backgroundColor: benefitsView === 2 ? '#F36E1E' : '#878787', minWidth: '120px'}}
                                    >{t('marketplaceBenefits.box2')}</Button>
                                </ButtonGroup>
                            </Box>
                            <Typography variant="h4" sx={{width: 'calc(100% - 32px)', color: 'black', fontSize: {xs: '22px', sm: '26px'}, fontWeight: 'bold'}} align="center">
                                { benefitsView === 1 ? t('marketplaceBenefits.subtitle1') : t('marketplaceBenefits.subtitle2') }
                            </Typography>
                        </>
                    }
                    <Box sx={{ display: 'flex', width: '100%', gap: {xs: '0.5rem', sm: '2rem'}, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        { productInUrl ? 
                            isAuthenticated && state?.tokenId ?
                                <MarketplaceBenefitsList
                                    ownNft = { true }
                                    title = { productInUrl && state?.tokenId ? t('marketplaceBenefits.benefitsListToken') + state?.tokenName : benefitsView === 1 ? t('marketplaceBenefits.benefitsList') : t('marketplaceBenefits.pointsList') }
                                    benefits = { ownBenefits }
                                    areBenefitsLoading = { areOwnBenefitsLoading }
                                    benefitsModal = { benefitsModal }
                                    setBenefitsModal = { setBenefitsModal }
                                    setConfirmModal = { setConfirmModal }
                                />
                                :
                                <MarketplaceBenefitsList
                                    ownNft = { false }
                                    title = { productInUrl ? t('marketplaceBenefits.benefitsProductList') : t('marketplaceBenefits.benefitsGlobalList') }
                                    benefits = { benefits }
                                    areBenefitsLoading = { areBenefitsLoading }
                                    benefitsModal = { benefitsModal }
                                    setBenefitsModal = { setBenefitsModal }
                                />
                            :
                            <>
                                <Typography variant="p" sx={{width: '100%', color: 'black', fontSize: {xs: '12px', sm: '18px'}}}>
                                    {t('marketplaceBenefits.select')}
                                </Typography>
                                <Box sx={{ position: 'relative', display: 'flex', width: '100%', gap: {xs: '0.5rem', sm: '2rem'}, justifyContent: 'center', alignItems: 'center' }}>
                                    {   !cellars.length ?
                                        <Typography variant="p" sx={{width: '100%', textAlign: 'center', color: 'red', fontSize: {xs: '12px', sm: '16px'}}}>
                                            {t('marketplaceBenefits.emptyCellars')}
                                        </Typography>
                                        :
                                        <>
                                        <FormControl fullWidth variant="outlined" sx={{ display: { sm: 'none' }}}>
                                            <InputLabel sx={{ background: 'white' }}>{t('registerForm.cellars')}</InputLabel>
                                            <Select
                                                name="cellarId"
                                                value={filters.cellarId ? filters.cellarId : ''}
                                                onChange={handleSelectChange}
                                                input={
                                                    <OutlinedInput
                                                        style={{
                                                            borderRadius: '55px'
                                                        }}
                                                    />
                                                }
                                                helperText={(null)} error={Boolean(null)}
                                            >
                                                { cellars.map((cellar, index) => (
                                                    <MenuItem key={ cellar.id } value={ cellar.id }>{ cellar.name }</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                            <Swiper
                                                spaceBetween={16}
                                                //slidesPerView={3}
                                                breakpoints={{
                                                    998: { slidesPerView: 3 },
                                                    768: { slidesPerView: 2 },
                                                    578: { slidesPerView: 1 }
                                                }}
                                                navigation= {{
                                                    nextEl: '.swiper-button-next',
                                                    prevEl: '.swiper-button-prev',
                                                }}
                                                //onSlideChange={() => console.log('slide change')}
                                                //onSwiper={(swiper) => console.log(swiper)}
                                                modules={[Navigation]}
                                            >
                                                {cellars.map((cellar, index) => (
                                                    <SwiperSlide key={cellar.id} elevation={0} onClick={(e) => handleFilterChange("cellarId", cellar.id)} style={{
                                                        width: '268px',
                                                        height: '160px',
                                                        backgroundColor: 'transparent',
                                                        background: filters.cellarId === cellar.id ? 'linear-gradient(to right, #FC6964, #FCC442)' : '',
                                                        padding: '2px',
                                                        boxSizing: 'border-box',
                                                        borderRadius: '10px',
                                                        cursor: 'pointer',
                                                        alignSelf: 'stretch',
                                                    }}>
                                                        <Card style={{
                                                            backgroundColor: '#160F31',
                                                            width: '100%',
                                                            height: '155px',
                                                            borderRadius: '10px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            }}>

                                                                <CardMedia
                                                                    component="img"
                                                                    style={{
                                                                        width: '100%',
                                                                        maxWidth: '220px',
                                                                        height: '100%',
                                                                        maxHeight: '128px',
                                                                        padding: '1rem',
                                                                        objectFit: 'contain'
                                                                    }}
                                                                    image={cellar?.image}
                                                                    alt={`Cellar ${index}`}
                                                                />
                                                        </Card>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                            <svg id="carousel-prev-button" className="swiper-button-prev" xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
                                                <path d="M14.8468 0.999986L2 13.8467L14.8468 26.6935" stroke="#F36E1E" strokeWidth="2"/>
                                            </svg>
                                            <svg id="carousel-next-button" className="swiper-button-next" xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
                                                <path d="M14.8468 0.999986L2 13.8467L14.8468 26.6935" stroke="#F36E1E" strokeWidth="2"/>
                                            </svg>
                                        </>
                                    }
                                </Box>
                                <Box>
                                    { benefitCategories.map((category, index) => (
                                        <Button 
                                            variant="outlined" 
                                            className={`${filters.benefitCategoryId === category.id ? 'gradient-border-button': 'btn-outline-gradient'}`} 
                                            sx={{margin:'.25rem', color: "white" }} 
                                            name="benefitCategoryId"
                                            value={category.id}
                                            onClick={handleSelectChange}
                                            >
                                            {category.nameEs}
                                        </Button>
                                    ))}
                                </Box>
                                { isAuthenticated && roles[0] === "ROLE_USER" ?
                                    <MarketplaceBenefitsList
                                        ownNft = { true }
                                        title = { benefitsView === 1 ? t('marketplaceBenefits.benefitsList') : t('marketplaceBenefits.pointsList') }
                                        benefits = { ownBenefits }
                                        areBenefitsLoading = { areOwnBenefitsLoading }
                                        benefitsModal = { benefitsModal }
                                        setBenefitsModal = { setBenefitsModal }
                                        setConfirmModal = { setConfirmModal }
                                    />
                                    : null
                                }
                                <MarketplaceBenefitsList
                                    ownNft = { false }
                                    title = { productInUrl ? null : t('marketplaceBenefits.benefitsGlobalList') }
                                    benefits = { benefits }
                                    areBenefitsLoading = { areBenefitsLoading }
                                    benefitsModal = { benefitsModal }
                                    setBenefitsModal = { setBenefitsModal }
                                />
                            </>
                        }

                        <Dialog open={ benefitsModal !== false ? true : false } onClose={ () => setBenefitsModal(false) } maxWidth="sm" fullWidth sx={{
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            "& .MuiDialog-paper": {
                                borderRadius: "10px",
                                }
                            }}>
                            <DialogContent sx={{
                                padding: '1rem 0rem 0rem 0rem',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                display: 'flex', gap: '0.25rem', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
                                border: '1px solid rgba(0, 0, 0, 0.25)',
                            }}>
                                <Box sx={{margin: '0rem', padding: '0.5rem 1rem', width: 'calc(100% - 2rem)', display: 'flex', flexDirection: 'row', gap: '0rem', justifyContent: 'end', alignItems: 'center'}}>
                                    <svg onClick={ () => setBenefitsModal(false) } style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M12.5 1L0.999999 12.5" stroke="black"/>
                                        <path d="M1 1L12.5 12.5" stroke="black"/>
                                    </svg>
                                </Box>
                                <CardMedia
                                    component="img"
                                    style={{
                                        width: '100%',
                                        maxWidth: '200px',
                                        height: '100%',
                                        maxHeight: '100px',
                                        objectFit: 'contain'
                                    }}
                                    image={benefitsModal?.image}
                                    alt={`Product ${benefitsModal?.id}`}
                                    />
                                <Box sx={{margin: '0rem', padding: '0.5rem 1rem', display: 'flex', flexDirection: 'column', width: 'calc(100% - 2rem)', gap: '0.5rem', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="h3" sx={{margin: '0rem !important', width: '100%', color: 'black', fontSize: '20px', fontWeight: '700'}}>
                                        { benefitsModal?.name }
                                    </Typography>
                                    <hr style={{margin: '0rem', width: '100%', background: '#D9D9D9', height: '1px', border: '0rem'}}></hr>
                                    <Typography variant="h4" sx={{margin: '0rem !important', width: '100%', color: 'black', fontSize: '14px', fontWeight: '600'}}>
                                        {t('marketplaceBenefits.validUntil')}
                                        <span style={{fontWeight: '400'}}>{ handleDate(benefitsModal?.expiration?.date) }</span>
                                    </Typography> 
                                    <Typography variant="h4" sx={{margin: '0rem !important', width: '100%', color: 'black', fontSize: '14px', fontWeight: '600'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
                                            <g clipPath="url(#clip0_862_416)">
                                                <path d="M21.3839 8.53782H14.6185V0H4.89271V17.349H0V17.995H25V17.349H21.3839V8.53782ZM13.9678 17.0756H5.54341V0.646052H13.9728V17.0707L13.9678 17.0756ZM20.7332 17.0756H14.7179V9.18885H20.7332V17.0756Z" fill="#F36E1E"/>
                                                <path d="M9.3036 2.45508H7.22234V4.24414H9.3036V2.45508ZM8.98073 3.91615H7.5452V2.7781H8.98073V3.91615Z" fill="#F36E1E"/>
                                                <path d="M12.2939 2.45508H10.2126V4.24414H12.2939V2.45508ZM11.971 3.91615H10.5355V2.7781H11.971V3.91615Z" fill="#F36E1E"/>
                                                <path d="M12.2939 4.77588H10.2126V6.55998H12.2939V4.77588ZM11.971 6.23695H10.5355V5.09891H11.971V6.23695Z" fill="#F36E1E"/>
                                                <path d="M12.2939 8.88574H10.2126V10.6748H12.2939V8.88574ZM11.971 10.3468H10.5355V9.20877H11.971V10.3468Z" fill="#F36E1E"/>
                                                <path d="M12.2939 12.9658H10.2126V14.7499H12.2939V12.9658ZM11.971 14.4269H10.5355V13.2888H11.971V14.4269Z" fill="#F36E1E"/>
                                                <path d="M9.3036 12.9658H7.22234V14.7499H9.3036V12.9658ZM8.98073 14.4269H7.5452V13.2888H8.98073V14.4269Z" fill="#F36E1E"/>
                                                <path d="M17.6038 9.88452H15.5226V11.6736H17.6038V9.88452ZM17.2809 11.3456H15.8454V10.2075H17.2809V11.3456Z" fill="#F36E1E"/>
                                                <path d="M20.2166 12.772H18.1353V14.5561H20.2166V12.772ZM19.8937 14.233H18.4582V13.095H19.8937V14.233Z" fill="#F36E1E"/>
                                            </g>
                                        <defs>
                                            <clipPath id="clip0_862_416">
                                            <rect width="25" height="18" fill="white"/>
                                            </clipPath>
                                        </defs>
                                        </svg>
                                        {t('marketplaceBenefits.locals')}
                                        {/*<span style={{fontWeight: '400'}}>{benefitsModal?.name}</span>*/}
                                    </Typography> 
                                    { benefitsModal?.commerces?.map((commerce, index) => (
                                        <Typography key={ commerce.id } variant="h4" sx={{margin: '0rem !important', width: '100%', color: 'black', fontSize: '12px', fontWeight: '500'}}>
                                            { commerce.name }
                                        </Typography> 
                                    ))}
                                    { benefitsModal?.terms ?
                                        <Typography variant="h4" sx={{margin: '0rem !important', width: '100%', color: "#F36E1E", fontSize: '12px', fontWeight: '700', textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => window.open(benefitsModal?.terms, "_blank")}
                                        >
                                            {t('marketplaceBenefits.datasheet')}
                                        </Typography>      
                                        : null
                                    }                        
                                </Box>
                                <Box sx={{margin: '0rem', padding: '0.5rem 1rem', display: 'flex', flexDirection: 'row', width: 'calc(100% - 2rem)', rowGap: '0.5rem', justifyContent: !benefitsModal?.redeemable ? 'start' : 'end', alignItems: 'center', background: '#F0F0F0' }}>
                                    {   benefitsModal?.redeemable || !benefitsModal ? 
                                        <Box sx={{margin: '0rem', padding: '0rem', display: 'flex', gap: '0rem', justifyContent: 'end', alignItems: 'center', minHeight: '36px'}}>
                                            { benefitsModal?.state === 2 ?
                                                <svg onClick={ (e) => { setBenefitsModal(false); setConfirmModal(benefitsModal); e.stopPropagation()} } style={{ cursor: 'pointer' }} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="OBJECTS">
                                                        <g id="Group">
                                                            <path id="Vector" d="M17.2705 22.6322C17.4566 22.8401 17.7228 22.9568 18 22.9568C18.2772 22.9568 18.5435 22.8401 18.7295 22.6322L23.0991 17.7921C23.3581 17.5039 23.4237 17.0918 23.2669 16.7343C23.1101 16.3805 22.7563 16.1508 22.3696 16.1508H20.3052V10.8584C20.3052 10.3149 19.8675 9.8772 19.324 9.8772H16.6724C16.1289 9.8772 15.6912 10.3149 15.6912 10.8584V16.1508H13.6268C13.2401 16.1508 12.8863 16.3805 12.7295 16.7343C12.5727 17.0881 12.6383 17.5039 12.8973 17.7921L17.2669 22.6322H17.2705Z" fill="#F36E1E"/>
                                                            <path id="Vector_2" d="M26.0645 24.1824H9.93557C9.4067 24.1824 8.97266 24.6128 8.97266 25.1453C8.97266 25.6778 9.40305 26.1082 9.93557 26.1082H26.0645C26.5933 26.1082 27.0237 25.6778 27.0237 25.1453C27.0237 24.6128 26.5933 24.1824 26.0645 24.1824Z" fill="#F36E1E"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                                : null
                                            }
                                        </Box>
                                        :
                                        <Typography variant="h6" sx={{margin: '0rem !important', width: '100%', color: 'black', fontSize: '14px', fontWeight: '500'}}>
                                            {t('marketplaceBenefits.modalWarning')}
                                            <span onClick={ () => navigate("/marketplace", {state: { benefitId: benefitsModal?.id }})} style={{color: '#F36E1E', cursor: 'pointer'}}>{t('marketplaceBenefits.modalWarningSpan')}</span>
                                        </Typography>
                                    }
                                </Box>
                            </DialogContent>
                        </Dialog>
                        <Dialog open={ confirmModal !== false ? true : false } onClose={ () => setConfirmModal(false) } maxWidth="sm" fullWidth sx={{
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            "& .MuiDialog-paper": {
                                borderRadius: "10px",
                                }
                            }}>
                            <DialogContent sx={{
                                padding: '1rem 0rem 0rem 0rem',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                display: 'flex', gap: '0.25rem', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
                                border: '1px solid rgba(0, 0, 0, 0.25)',
                            }}>
                                <Box sx={{margin: '0rem', padding: '0.5rem 1rem', width: 'calc(100% - 2rem)', display: 'flex', flexDirection: 'row', gap: '0rem', justifyContent: 'end', alignItems: 'center'}}>
                                    <svg onClick={ () => setConfirmModal(false) } style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M12.5 1L0.999999 12.5" stroke="black"/>
                                        <path d="M1 1L12.5 12.5" stroke="black"/>
                                    </svg>
                                </Box>
                                <Box sx={{margin: '0rem', padding: '0.5rem 1rem', display: 'flex', flexDirection: 'column', width: 'calc(100% - 2rem)', gap: '0.5rem', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="h3" sx={{margin: '0rem !important', width: '100%', color: 'black', fontSize: '20px', fontWeight: '700', textAlign: 'center'}}>
                                        {t('marketplaceBenefits.modalConfirm')}
                                    </Typography>                      
                                </Box>
                                <Box sx={{margin: '0rem', padding: '0.5rem 1rem', display: 'flex', flexDirection: 'row', width: 'calc(100% - 2rem)', rowGap: '0.5rem', justifyContent: 'center', background: '#F0F0F0' }}>
                                    { !isBenefitRedeeming ?
                                        <Button
                                            style={{ color: 'transparent' }}
                                            onClick={ () => redeemBenefit(confirmModal.token, confirmModal.id) }
                                            className={ "btn-gradient" }
                                        >{t('marketplaceBenefits.buttonConfirm')}</Button>
                                        :
                                        <Typography variant="p" sx={{width: '100%', height: '44px', lineHeight: '44px', textAlign: 'center', color: 'red', fontSize: {xs: '12px', sm: '16px'}}}>
                                            {t('marketplaceBenefits.redeeming')}
                                        </Typography>
                                    }
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </Box>
            )}
            </Grid>
            <ErrorDialog
                open={!!error}
                onClose={() => setError(null)}
                alertTitle={t('globals.navbar.beneficios')}
                alertMessage={error}
                dark={false}
            />
        </Container>
        );
}

export default MarketplaceBenefitsComponent;
