import React, {useState, useEffect} from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Container, Box, DialogContent, Dialog, CardMedia } from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../contexts/AuthContext";
import BenefitsRepository from '../../../data/repository/BenefitsRepository';
import ErrorDialog from "../ErrorDialog";

const UserBenefitsComponent = () => {
    const [t, i18n] = useTranslation();

    const { isAuthenticated, roles } = useAuth();

    const [rows, setRows] = useState([]);
    const [areRowsLoading, setAreRowsLoading] = useState(false);
    const [qr, setQr] = useState(false);
    const [isBenefitRedeeming, setIsBenefitRedeeming] = useState(false);
    const [filters, setFilters] = useState({
        cellarId: null,
        productId: null
    });
    const benefitsRepository = new BenefitsRepository();
    const [error, setError] = useState(null);

    const handleDate = (date) => {
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        
        
        const formattedToday = dd + '/' + mm + '/' + yyyy;
        
        return formattedToday;
    };

    const fetchUserBenefits = async (usedFilter) => {
        setAreRowsLoading(true);
        try {
            var benefits;
            if (roles[0] === "ROLE_USER") {
                benefits = await benefitsRepository.getOwnBenefitsFiltered(usedFilter, i18n.language);
            } else {
                benefits = await benefitsRepository.getCommerceBenefitsFiltered(usedFilter, i18n.language);
            }
            setRows(benefits);
        } catch (error) {
            console.error('Error al obtener beneficios del usuario:', error);
        }
        setAreRowsLoading(false);
    };

    const redeemBenefit = async (nft, benefit) => {
        setIsBenefitRedeeming(true);
        try {
            await benefitsRepository.redeemBenefit(nft, benefit);
        } catch (error) {
            setError(error === "redeemBenefit.error.00001" ? t('home.benefits.redeem.error1') 
                : error === "redeemBenefit.error.00002" ? t('home.home.benefits.redeem.error2') 
                : error === "redeemBenefit.error.00003" ? t('home.benefits.redeem.error3')
                : error === "redeemBenefit.error.00004" ? t('home.benefits.redeem.error4')
                : "Error: " + error
            )

            console.error("Error redeeming benefit:", error);
        }
        fetchUserBenefits(filters);
        setTimeout(() => {
            setIsBenefitRedeeming(false);
        }, 2000);
    }

    const downloadQR = async(nft) => {
        try {
            const response = await benefitsRepository.downloadBenefit(nft);
            setQr(response);
        } catch (error) {
            console.error("Error fetching benefits:", error);
        }
    };

    useEffect(() => {
        fetchUserBenefits(filters);
    }, [i18n.language]);

    return (
        <Container maxWidth="lg" sx={{ paddingBottom: '1rem' }}>
            <Typography variant="h1" style={{ color: '#F36E1E', padding: '10px', textAlign: 'center', fontSize: '35px', fontWeight: 'bold', marginTop: '2rem' }}>
                {t('myBenefits.title')}
            </Typography>
            { !rows.length ?
                <Box sx={{ width: 'calc(100% - 32px)', display: 'flex', padding: '1rem', gap: {xs: '0.5rem', sm: '1rem'},justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="p" sx={{width: '100%', textAlign: 'center', color: 'red', fontSize: {xs: '12px', sm: '16px'}}}>
                        { areRowsLoading ? t('myBenefits.loadingBenefits') : t('myBenefits.emptyBenefits') }
                    </Typography>
                </Box>
                :
                <TableContainer component={Paper} style={{marginTop: '3rem'}}>
                    <Table aria-label="benefits-table">
                        <TableHead>
                            <TableRow>
                                { roles[0] === "ROLE_USER" ? <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle1')}</TableCell> : null }
                                { roles[0] === "ROLE_USER" ? <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle8')}</TableCell> : null }
                                { roles[0] === "ROLE_USER" ? <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle9')}</TableCell> : null }
                                <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle2')}</TableCell>
                                { roles[0] === "ROLE_USER" ? <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle3')}</TableCell> : null }
                                <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle7')}</TableCell>
                                <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle4')}</TableCell>
                                { roles[0] === "ROLE_USER" ?<TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle5')}</TableCell> : null }
                                { roles[0] === "ROLE_USER" ? <TableCell style={{ color: '#F36E1E', fontWeight: 'bold' }}>{t('myBenefits.tableTitle6')}</TableCell> : null }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { rows.map((row) => (
                                <TableRow key={row.id}>
                                    { roles[0] === "ROLE_USER" ? <TableCell>{ row?.cellar?.name }</TableCell> : null }
                                    { roles[0] === "ROLE_USER" ? <TableCell>{ t(`myBenefits.${row?.type}`) }</TableCell> : null }
                                    { roles[0] === "ROLE_USER" ? <TableCell>{ row?.pricePoints }</TableCell> : null }
                                    <TableCell>{ row?.name }</TableCell>
                                    { roles[0] === "ROLE_USER" ? <TableCell>
                                        { row.state === 3 ? 
                                            <IconButton
                                                color="primary"
                                                onClick={ () => downloadQR(row.benefitToken)} 
                                                style={{ color: '#F36E1E', padding: '0rem' }}
                                            >
                                                <VisibilityIcon/>
                                            </IconButton>
                                            : null
                                        }
                                    </TableCell> : null }
                                    <TableCell>{ handleDate(row?.effective?.date) }</TableCell>
                                    <TableCell>{ handleDate(row?.expiration?.date) }</TableCell>
                                    { roles[0] === "ROLE_USER" ? <TableCell>{ row?.state === 1 ? t('myBenefits.noActive') : row?.state === 2 ? t('myBenefits.toRedeem') : row?.state === 3 ? t('myBenefits.active') : row?.state === 4 ? t('myBenefits.used') : row?.state === 5 ? t('myBenefits.expired') : null }</TableCell> : null }
                                    <TableCell>
                                        { roles[0] === "ROLE_USER" && row.state === 2 ? 
                                            <IconButton
                                                color="primary"
                                                onClick={ () => redeemBenefit(row.token, row.id) }
                                                style={{ color: '#F36E1E', padding: '0rem' }}
                                            >
                                                <ArrowCircleDownIcon/>
                                            </IconButton>
                                        : '-' }
                                    </TableCell> 
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <Dialog open={ qr !== false ? true : false } onClose={ () => setQr(false) } maxWidth="sm" fullWidth sx={{
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                "& .MuiDialog-paper": {
                    borderRadius: "10px",
                    }
                }}>
                <DialogContent sx={{
                    padding: '1rem 0rem',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    display: 'flex', gap: '0.25rem', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
                    border: '1px solid rgba(0, 0, 0, 0.25)',
                }}>
                    <Box sx={{margin: '0rem', padding: '0.5rem 1rem', width: 'calc(100% - 2rem)', display: 'flex', flexDirection: 'row', gap: '0rem', justifyContent: 'end', alignItems: 'center'}}>
                        <svg onClick={ () => setQr(false) } style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path d="M12.5 1L0.999999 12.5" stroke="black"/>
                            <path d="M1 1L12.5 12.5" stroke="black"/>
                        </svg>
                    </Box>
                    <CardMedia
                        component="img"
                        style={{
                            width: '100%',
                            maxWidth: '280px',
                            height: '100%',
                            maxHeight: '280px',
                            objectFit: 'contain'
                        }}
                        image={ qr}
                        alt={`qr-benefit`}
                    />
                </DialogContent>
            </Dialog>
            <ErrorDialog
                open={!!error}
                onClose={() => setError(null)}
                alertTitle={t('globals.navbar.beneficios')}
                alertMessage={error}
                dark={false}
            />
        </Container>
    );
}

export default UserBenefitsComponent;